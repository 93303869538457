/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div",
    strong: "strong"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/brands/unitron/'], ['en', 'https://www.hear.com/hearing-aids/brands/unitron/'], ['en-US', 'https://www.hear.com/hearing-aids/brands/unitron/'], ['en-CA', 'https://ca.hear.com/hearing-aids/brands/unitron/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The ability to hear has a huge influence on the general quality of life. With state-of-the-art hearing aid systems, the Canadian quality manufacturer Unitron has given back some quality of life to those with impaired hearing. Since 1964, it has been Unitron’s objective to reconcile speech intelligibility and natural hearing without compromise."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Modern hearing aids can handle numerous hearing environments. However, if wearer’s needs change, even leading-edge devices are limited. Due to limited properties, it cannot provide optimal support in every hearing environment. Unitron develops hearing aids able to adjust to the requirements of the wearer at any stage."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-perfect-hearing-aid-for-every-situation-by-unitron",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-perfect-hearing-aid-for-every-situation-by-unitron",
    "aria-label": "the perfect hearing aid for every situation by unitron permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The perfect hearing aid for every situation by Unitron"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "But how to change a hearing aid’s features? Canadian manufacturer Unitron’s solution is simple. Based on Moxi Kiss™, it has everything necessary for expansion later. If the wearer’s needs change, the hearing aid can be upgraded and features enhanced. Automatic SmartFocus™ can therefore be improved by adding new functions such as SpeechZone™. Unitron thus provides hearing aid wearers with long-term hearing success."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "unitron-hearing-aids-the-perfect-hearing-system-for-all-levels-of-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#unitron-hearing-aids-the-perfect-hearing-system-for-all-levels-of-hearing-loss",
    "aria-label": "unitron hearing aids the perfect hearing system for all levels of hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Unitron hearing aids: The perfect hearing system for all levels of hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Unitron offers many different designs of hearing aid, each with their own advantages in size, design and technical features. Basically we distinguish between In-The-Ear (ITE) and Behind-The-Ear (BTE) hearing aids. For both versions, Unitron’s range incorporates several optional selections serving different degrees of wearing comfort. The model series Quantum is available in IDE versions such as Concha, Semi-Concha, and as an Canal and Mini-Canal hearing system."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You can also choose from several designs for the Moxi² and Quantum². Aside from the classic Behind-The-Ear hearing aid, with optional open fitting, models are also available as RIC hearing aids with external receivers. The large selection of individual hearing aids allows you to choose a hearing solution, to suit your lifestyle. More detailed information on which hearing aid for which level of deafness can be determined more accurately following a hearing test conducted by hearing aid provider."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "unitron-news",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#unitron-news",
    "aria-label": "unitron news permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Unitron News"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Moxi²")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "It’s no surprise that everyone is thrilled by the natural sound quality of Moxi². Three cutting-edge versions each with four different technology levels and unique selection of various technical accessories. Moxi² automatically clarifies speech and simplifies hearing, regardless of where you are and what you may be doing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Quantum²")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In loud restaurants, during quiet conversations and everything in between – Quantum² guarantees absolutely natural sound quality – everywhere. You have the choice between four different technology levels, with a unique selection of various functions to adjust to your everyday life."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Even more hearing enjoyment from Unitron")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In developing hearing aids, Unitron places great value on the compatibility of natural sound and speech intelligibility. To prevent making any compromises whatsoever, hearing aids can be connected to cellphones, TVs or MP3 players through uDirect™ 2. The advantage of a direct connection is the pure and brilliant sound enabled by direct sound transmission to the hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "uDirekt™ 2 simultaneously serves as a remote control for the hearing aids of platforms Era™, Passport™ and Latitude™. This connectivity option and many others provided by Unitron convert the use of hearing devices into pure hearing enjoyment."), "\n", React.createElement(ButtonCta, {
    copy: "TRY UNITRON HEARING AIDS NOW",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
